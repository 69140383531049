import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getInforme,
  getInformePublico,
  updateResume,
  getMessagesPublic,
  createMessage
} from '../Api/vistaInforme.api'
import { NodoContext } from '../Context/nodoContext'
import { LayoutContext } from '../Context/layoutContext'
import { AppBar, IconButton, Toolbar, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { FavoritosTabs } from '../Favoritos/favoritosTabs'
import DrawerTopBar from '../Home/containers/drawerTopbar'
import LogoComponent from '../Home/containers/logoComponent'
import MySettings from '../Home/containers/mySettingsComponent'
import { AuthContext } from '../Context/authContext'
import { validateTokenApiCall } from '../Api/auth.api'
import { descomprimirPayload, downloadInformeS3 } from '../Utils/utilities'
import { getFilePresignedUrl } from '../Api/files.api'

export function InformesDetallePublico() {
  const [informe, setInforme] = React.useState(null)
  const [nombreInforme, setNombreInforme] = React.useState('')
  const [resumenInforme, setResumenInforme] = React.useState('')
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { fireInformeReload } = React.useContext(LayoutContext)
  const { getToken } = React.useContext(AuthContext)
  const { informeId } = useParams()
  const [permissionDenied, setPermissionDenied] = React.useState(false)
  const [messages, setMessages] = React.useState([])
  const navigate = useNavigate()

  useEffect(() => {
    validateToken()
  }, [informeId, fireInformeReload])

  function validateToken() {
    if (getToken() !== null) {
      validateTokenApiCall().then((response) => {
        if (response.status === 200) {
          navigate('/home/informes/' + informeId)
        } else {
          updateInforme()
        }
      })
    } else {
      updateInforme()
    }
  }

  function updateInforme() {
    if (!informeId) return
    setLoadingMessage('Cargando informe...')
    setLoading(true)
    getInformePublico(informeId).then(async (response) => {
      setLoading(false)
      if (response.status === 200) {
        let dataInforme = {}
        if (response.body.informe.data) {
          dataInforme = descomprimirPayload(response.body.informe.data)
        } else if (response.body.informe.pathS3) {
          // obtener de S3
          const resp = await getFilePresignedUrl(response.body.informe.pathS3);
          if (resp.status !== 200) {
            toast.error(`Error al obtener el informe: ${resp.body}`);
            return;
          }
          const { data, error } = await downloadInformeS3(resp.body.url);
          if (error) {
            toast.error(`Error al obtener el informe: ${data}`);
            return;
          }
          dataInforme = data;
        }
        setNombreInforme(response.body.informe.nombre)
        setResumenInforme(response.body.informe.resumen)
        createSummaryHash(dataInforme, response.body.summary)
      } else if (response.status === 401 || response.status === 404) {
        setPermissionDenied(true)
      } else {
        toast.error('Ocurrió un error al obtener el informe')
      }
    })

    function createSummaryHash(informe, summary) {
      // const informe = JSON.parse(informeString)
      const hash = {}
      summary.archivoSummary.forEach((item) => {
        hash[item.tabId] = {
          comentarios: 0,
          archivos: item.total
        }
      })
      summary.commentSummary.forEach((item) => {
        hash[item.tabId] = {
          comentarios: item.total,
          archivos: hash[item.tabId] ? hash[item.tabId].archivos : 0
        }
      })
      informe.tabs.forEach((tab) => {
        if (!hash[tab.id]) {
          tab.comentarios = 0
          tab.archivos = 0
        } else {
          tab.comentarios = hash[tab.id].comentarios
          tab.archivos = hash[tab.id].archivos
        }
      })
      setInforme(informe)
    }

    getMessagesPublic(informeId).then((response) => {
      setMessages(response.body)
    })
  }

  const grabarMensaje = async (data) => {
    await createMessage(data)
    getMessagesPublic(informeId).then((response) => {
      setMessages(response.body)
    })
  }

  const actualizaResumen = async (data) => {
    try {
      updateResume(data)
    } catch (error) {
      console.error(error)
    }
  }

  let inf
  if (!resumenInforme) {
    inf = { id: informeId, resumen: '', mensajes: messages }
  } else {
    inf = { id: informeId, resumen: resumenInforme, mensajes: messages }
  }

  return (
    <div>
      <AppBar
        position="fixed"
        open={false}
        drawerwidth={0}
        sx={{
          backgroundColor: '#202e3c',
          flex: 1
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex' }}>
            <LogoComponent />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MySettings />
          </div>
        </Toolbar>
      </AppBar>
      {permissionDenied && (
        <div>
          <DrawerTopBar />
          <div
            style={{
              marginTop: '85px',
              maxHeight: 'calc(100vh - 105px)',
              overflowY: 'auto'
            }}
          >
            <h1>Acceso denegado</h1>
            <h3>
              El informe al que intenta acceder no existe o no es público. Por
              favor, contacte con el administrador.
            </h3>
          </div>
        </div>
      )}
      {!permissionDenied && (
        <div
          style={{
            marginTop: '85px',
            maxHeight: 'calc(100vh - 105px)',
            overflowY: 'auto'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              paddingLeft: '10px'
            }}
          >
            <Tooltip title="Volver">
              <IconButton
                onClick={() => {
                  navigate('/home/tableros')
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </div>
          <h1>{nombreInforme}</h1>
          <h3>{informe?.descripcion}</h3>
          {informe?.tabs && (
            <FavoritosTabs
              tabs={informe.tabs}
              leyendas={informe.leyendas}
              esInforme={true}
              informe={inf}
              grabarMensaje={grabarMensaje}
              resultadosEvaluacion={informe.resultadosEvaluacion}
              esTableroPersonal={informe.tableroPersona}
            />
          )}
        </div>
      )}
    </div>
  )
}
